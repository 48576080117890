.shows-container {
    position: absolute;
    top: 180px;
    display: flex;
    padding-left: 125px;
}

@media only screen and (max-width: 1030px) {
    .shows-container {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 850px) {
    .shows-container {
        display: block;
    }
}

@media only screen and (max-width: 850px) {
    .shows-container {
        top: 110px;
        padding-left: 25px;
    }
}

.flex--1 {
    flex: 1;
}

.flex--2 {
    flex: 3
}