.login {
    font-family: IBMPlexSansRegular;
}

.login__container {
}

.login__column1,
.login__column2 {
    display: inline-block;
    vertical-align: middle;
}

.login__column1 {
    width: 27%;
    margin-left: 175px;
}

.login__content {
    color: #000000;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.login__action {
    color: #FFFFFF;
    background: #000000;
    padding: 9px 10px;
    border-radius: 24px;
    border: unset;
    box-shadow: unset;
    cursor: pointer;
}

.login__column2 {
    width: 50%;
}

.login__placeholder {
    width: 100%;
}

@media (max-width: 900px) {

    .login__column1,
    .login__column2 {
        display: block;
        width: 90%;
        margin: 50px auto 20px auto;
        text-align: center;
    }
}