.shows-card__outer-container{
    width: 210px;
    height: 328px;
    margin: 12px 24px;
    -webkit-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    -moz-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    border-radius: 11px;
    background-color: #ffffff;
    cursor: pointer;
}
.shows-img{
    width: 210px;
    height: 180px;
    margin-bottom: 24px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #f6f5f5;
}
.shows-heading{
    font-size: 16px;
    font-weight: 600;
    color: #504f4f;
}
.shows-category{
    font-size: 14px;
    font-weight: 300;
    margin: 0px 10px 15px 10px;
    color: #504f4f;
}
.episode-count{
    font-size: 12px;
    color: #787878;
    font-weight: 400;
}
.text-align--center{
    margin: 0px 10px;
}