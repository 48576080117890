.list-podcast{
    padding-left:170px;
    padding-right:5%;
    padding-bottom: 1%;
}

.list-podcast__column1,.list-podcast__column2{
    display: inline-block;
    vertical-align: middle;
    width: 35%;
}

.list-podcast__column2{
    width: 55%;
    margin-left: 3%;
    margin-bottom: 25px;
}

.list-podcast__column3{
    display: inline-block;
    vertical-align: top;
    width: 15%;
    margin: 20px 2% 0 0;
}

.list-podcast__column3 img{
    width: 100%;
}

.list-podcast__column4{
    display: inline-block;
    vertical-align: top;
    width: 82%;
}

.list-podcast__title{
    font-weight: bolder;
    font-size: 1.5rem;
}

.list-podcast__description{
    font-weight: normal;
    font-size: 1rem;
}

.list-podcast__item{
    box-shadow: 0 5px 40px -10px rgb(0 0 0 / 10%);
    border-radius: 4px;
    width: 100%;
}

.list-podcast__image img{
    width: 100%;
    border-radius: 4px 0 0 4px;
    object-fit: cover;
}

.list-podcast__content{
    position:relative;
    width: 100%;
    padding: 10px 20px;
}

.list-podcast__item__title,.list-podcast__item__description{
    font-size: 1.75rem;
    color: #003343;
    margin-top: 5px;
    font-weight: bold;
}

.list-podcast__item__description{
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 200;
}

.list-podcast__item__created{
    color: #000000;
    font-size: 0.9rem;
}

.list-podcast__item__created a{
    text-decoration: none;
    color: #003343 !important;
}

.list-podcast__item__content{
    margin-top: 25px;
}

.list-podcast__player__controls{
    display: inline-block;
    vertical-align: text-bottom;
    width: 65px;
    z-index: 1;
    text-align: right;
    position:relative;
}
.list-podcast__player__controls .anticon-undo{
    margin-right:10px;
}
.list-podcast__player{
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 65px);
    z-index:0;
    position: relative;
    margin-left: -10px;
}

audio,audio::-webkit-media-controls-panel{
    background: #fff;
    outline: unset;
}

@media (max-width: 900px) {
    .list-podcast{
        padding-left: 0;
        padding-right: 0;
    }
    .list-podcast__description,.list-podcast__column3{
        display: none;
    }

    .list-podcast__column1,.list-podcast__column2,.list-podcast__column4{
        display: block;
        text-align: center;
        width: 95%;
        margin: 20px auto;
    }

    .list-podcast__column4{
        width: 80%;
        margin: 20px auto;
        text-align: left;
    }
}
